exports.components = {
  "component---src-components-templates-blog-blog-page-tsx": () => import("./../../../src/components/templates/Blog/BlogPage.tsx" /* webpackChunkName: "component---src-components-templates-blog-blog-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-contacts-tsx": () => import("./../../../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-cryotherapy-body-therapy-xtone-tsx": () => import("./../../../src/pages/cryotherapy/body-therapy-xtone.tsx" /* webpackChunkName: "component---src-pages-cryotherapy-body-therapy-xtone-tsx" */),
  "component---src-pages-cryotherapy-face-therapy-xcryo-mask-tsx": () => import("./../../../src/pages/cryotherapy/face-therapy-xcryo-mask.tsx" /* webpackChunkName: "component---src-pages-cryotherapy-face-therapy-xcryo-mask-tsx" */),
  "component---src-pages-cryotherapy-full-body-therapy-xcabin-tsx": () => import("./../../../src/pages/cryotherapy/full-body-therapy-xcabin.tsx" /* webpackChunkName: "component---src-pages-cryotherapy-full-body-therapy-xcabin-tsx" */),
  "component---src-pages-cryotherapy-local-therapy-xcryo-original-tsx": () => import("./../../../src/pages/cryotherapy/local-therapy-xcryo-original.tsx" /* webpackChunkName: "component---src-pages-cryotherapy-local-therapy-xcryo-original-tsx" */),
  "component---src-pages-cryotherapy-skin-therapy-xcryo-boosters-tsx": () => import("./../../../src/pages/cryotherapy/skin-therapy-xcryo-boosters.tsx" /* webpackChunkName: "component---src-pages-cryotherapy-skin-therapy-xcryo-boosters-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-info-blog-tsx": () => import("./../../../src/pages/info/blog.tsx" /* webpackChunkName: "component---src-pages-info-blog-tsx" */),
  "component---src-pages-info-faq-tsx": () => import("./../../../src/pages/info/faq.tsx" /* webpackChunkName: "component---src-pages-info-faq-tsx" */),
  "component---src-pages-info-gallery-tsx": () => import("./../../../src/pages/info/gallery.tsx" /* webpackChunkName: "component---src-pages-info-gallery-tsx" */),
  "component---src-pages-info-press-tsx": () => import("./../../../src/pages/info/press.tsx" /* webpackChunkName: "component---src-pages-info-press-tsx" */),
  "component---src-pages-reviews-tsx": () => import("./../../../src/pages/reviews.tsx" /* webpackChunkName: "component---src-pages-reviews-tsx" */),
  "component---src-pages-services-business-and-cryo-tsx": () => import("./../../../src/pages/services/business-and-cryo.tsx" /* webpackChunkName: "component---src-pages-services-business-and-cryo-tsx" */),
  "component---src-pages-services-cost-coolsculpting-machine-tsx": () => import("./../../../src/pages/services/cost-coolsculpting-machine.tsx" /* webpackChunkName: "component---src-pages-services-cost-coolsculpting-machine-tsx" */),
  "component---src-pages-services-cryotherapy-startup-cost-tsx": () => import("./../../../src/pages/services/cryotherapy-startup-cost.tsx" /* webpackChunkName: "component---src-pages-services-cryotherapy-startup-cost-tsx" */),
  "component---src-pages-services-markets-tsx": () => import("./../../../src/pages/services/markets.tsx" /* webpackChunkName: "component---src-pages-services-markets-tsx" */),
  "component---src-pages-services-prices-tsx": () => import("./../../../src/pages/services/prices.tsx" /* webpackChunkName: "component---src-pages-services-prices-tsx" */),
  "component---src-pages-sitemap-tsx": () => import("./../../../src/pages/sitemap.tsx" /* webpackChunkName: "component---src-pages-sitemap-tsx" */)
}

