module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[],"threshold":0.4,"once":true,"disable":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"mobile":"(min-width: 320px)","mobileM":"(min-width: 360px)","mobileL":"(min-width: 568px)","tablet":"(min-width: 768px)","tabletL":"(min-width: 1024px)","laptopS":"(min-width: 1140px)","laptop":"(min-width: 1366px)","desktop":"(min-width: 1680px)","xxl":"(min-width: 1920px)"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Manrope:400,700,800&display=swap"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"i18n","languages":["en","de","es","fi","fr","it","se","dk","no"],"defaultLanguage":"en","fallbackLanguage":"en","redirect":true,"siteUrl":"https://cryosculptor.com","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":".","nsSeparator":false}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
